import {DceComponent, DceHTMLElement} from "dce-engine";
import Dce from "dce-engine/lib/decorators/Dce";

interface PaginatorInputProps {
}

@Dce("paginatorInput")
export default class ShowMore extends DceComponent<PaginatorInputProps> {

    private input: HTMLInputElement;

    private current: number = 0;
    private max: number = 0;

    constructor(elem: DceHTMLElement, props: PaginatorInputProps) {
        super(elem, props);

        this.input = elem as unknown as HTMLInputElement;
        this.current = parseInt(elem.getAttribute('data-page'));
        this.max = parseInt(elem.getAttribute('data-maxpage'));

        let location = window.location.origin + window.location.pathname;

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('page');

        elem.addEventListener("change", (e) => {

            let value = parseInt(this.input.value);

            if(value > this.max) {
                value = this.max;
            }

            if(value != this.current){
                searchParams.set('page', value + "");

                window.location.replace(
                    location + "?" + searchParams.toString()
                );

            }

        });



    }

}